<template>
  <div>
    <!-- Affiche tout sauf le composant choix-instance -->
    <div v-if="!showCompChoixInstance">
      <!-- En-tête -->
      <entete></entete>
      <main>
        <form class="container" @submit.prevent="VerifierLogin">
          <div class="column mdRow rowTextuel">
            <div class="colIntro col100 colMd60">
              <h1>Administration Autoref</h1>
              <h2>Votre outil</h2>
              <p>
                Dédié pour créer, gérer et diffuser vos contenus web
                <br />votre dashboard, votre univers en toute simplicité !
              </p>
            </div>
          </div>
          <div class="rowInputs">
            <div class="row rowLabel">
              <label for="log_log">Identifiant</label>
              <label for="pwd_log">Mot de passe</label>
            </div>
            <div class="column mdRow rowForm rowFormConnexion">
              <input type="text" class="formControl fs21" placeholder="Identifiant" v-model="log_log"
                @keyup.enter="focusSuivant('pwd')" ref="log_log" />

              <input type="password" class="formControl" placeholder="Mot de passe" v-model="pwd_log" id="pwd_log"
                ref="pwd" @keyup.enter="focusSuivant('connexion')" />

              <button type="button" id="connexion" ref="connexion" class="btn btnPlain btnConnexion"
                @keyup.enter="VerifierLogin()" @click="VerifierLogin()">
                Se connecter
              </button>
            </div>

            <!-- ERREUR DE SAISIE - AFFICHAGE DU MESSAGE D'ERREUR POUR L'UTILISATEUR -->
            <div class="alert alertDanger" v-if="retourError">
              Identifiant ou mot de passe incorrect
            </div>
          </div>
        </form>
      </main>
    </div>

    <!-- Affiche uniquement le composant choix-instance -->
    <comp-choix-instance v-else :tabAdh="tabAdh" />
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import entete from "@/components/comp.entete.vue";
import CompChoixInstance from "@/components/comp.choixInstance.vue";
const router = useRouter();

export default {
  name: "index",
  created() {
    this.$store.dispatch("resetState");
    //sessionStorage.clear();
    // localStorage.clear();
  },
  directives: {
    focus: {
      mounted(el) {
        el.focus();
      },
    },
  },
  components: {
    entete,
    CompChoixInstance,
  },
  data() {
    return {
      log_log: "",
      pwd_log: "",
      retourError: false,
      showCompChoixInstance: false, // Contrôle l'affichage du composant
      tabAdh: [],
      selectedAdh: [],
      urlApi: process.env.VUE_APP_API_URL,
      portApi: process.env.VUE_APP_PORT,
    };
  },
  mounted() {
    document.body.classList.remove("dashboard");
  },
  methods: {
    focusSuivant(nextRef) {
      this.$refs[nextRef].focus();
    },

    async VerifierLogin () {
      const sessionData = {
        logLog: this.log_log,
        pwdLog: this.pwd_log,
      };

      try {
        const response = await fetch(`${this.urlApi}:${this.portApi}/api/verifierLogin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(sessionData),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok verifierLogin");
        }
        const data = await response.json();
        console.log(data.retour);
        switch(data.retour)
        {
          case 'OK':
            this.$store.commit("adhlogin/setLogLog", this.log_log);
            this.$store.commit("adhlogin/setPwdLog", this.pwd_log);
            this.$store.commit("adhlogin/setIdAdhlogin", data.id_adhlogin);
            this.$store.commit("adhlogin/setIdFirep", data.id_firep);

            if (data.sta_log == "A") {
              // Redirection vers un autre portail
              this.$store.commit("gadherent/setInsAdh", data.ins_adh);
              this.$store.commit("gadherent/setIdGadherent", data.id_gadherent);
              this.$store.commit("gadherent/setNomAdh", data.nom_adh);
              this.$router.push({ name: "tableauDeBord" });
            } else {
              // BASTION
              // Affiche le composant comp-choix-instance
              this.showCompChoixInstance = true;
              this.tabAdh = data.tabAdh; // Transmettre les données nécessaires au composant
            }
          break;
          case 'DESAC':
            this.$swal.fire({
              icon: "error",
              title:
                "Votre login est désactivé, veuillez contacter votre administrateur.",
              showCloseButton: true,
              allowOutsideClick: false,
            });
          break;
          case 'ERREUR':
            this.retourError = true;
            this.pwd_log = "";
            this.$refs.log_log.focus();
          break;
        }
      }
      catch (error) {
        console.error("Error fetching items: verifierLogin", error);
      }

    },
  },
};
</script>
<style scoped></style>
