import { createRouter, createWebHistory } from 'vue-router'
import PageIndex from '@/views/index.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: PageIndex
  },
  {
    path: '/tableauDeBord',
    name: 'tableauDeBord',
    component: () => import('../views/tableauDeBord.vue'),
  },
  {
    path: '/maintApplicatif',
    name: 'maintApplicatif',
    component: () => import('../views/maintApplicatif.vue'),
  },
  {
    path: '/maintModule',
    name: 'maintModule',
    component: () => import('../views/maintModule.vue'),
  },
  {
    path: '/maintAdhlogin',
    name: 'maintAdhlogin',
    component: () => import('../views/maintAdhlogin.vue'),
  },
  {
    path: '/maintAdhcli',
    name: 'maintAdhcli',
    component: () => import('../views/maintAdhcli.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
